import React, { useRef } from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";
import { Contact } from "../components/sections/Contact/index";
import { NavigationBullets } from "../components/NavigationBullets/NavigationBullets";

const Home = () => {
  const radioRef = useRef(null);
  const heroRef = useRef(null);
  const workRef = useRef(null);
  const projectsRef = useRef(null);
  return (
    <>
      <NavigationBullets
        heroRef={heroRef}
        workRef={workRef}
        projectRef={projectsRef}
        ref={radioRef}
      />
      <Hero
        className="illustration-section-01"
        ref={heroRef}
        radioRef={radioRef}
      />
      <FeaturesTiles ref={workRef} radioRef={radioRef} />
      <FeaturesSplit
        topDivider
        imageFill
        className="illustration-section-02"
        ref={projectsRef}
        radioRef={radioRef}
      />
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
};

export default Home;
