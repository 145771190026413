import React from "react";
import { Contact } from "../Contact";
import { FooterExperience } from "../FooterExperience";
import { FooterProjects } from "../FooterProjects";
import "./index.css";
export const FooterUpper = () => {
  return (
    <div className="footer-upper">
      <Contact />
      <FooterExperience />
      <FooterProjects />
    </div>
  );
};
