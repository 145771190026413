import React from "react";

export const Testimonial = ({ author, comment, designation, profileLink }) => (
  <div className="tiles-item reveal-from-right" data-reveal-delay="200">
    <div className="tiles-item-inner">
      <div className="testimonial-item-content">
        <p className="text-sm mb-0">— {comment}</p>
      </div>
      <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
        <span className="testimonial-item-name text-color-high">
          <a style={{ fontSize: 16 }} href={profileLink}>
            {author}
          </a>
        </span>
      </div>
      <div>
        <span style={{ fontSize: 16 }} className="testimonial-item-link">
          {designation}
        </span>
      </div>
    </div>
  </div>
);
