import React from "react";
import "./index.css";
import classNames from "classnames";
import { openInNewTab } from "../../utils/commons";
import { ProjectGallery } from "../ProjectGallery";
import Button from "../elements/Button";
import Image from "../elements/Image";
import { useLogo } from "../../hooks/useImage";
import { v4 as uuid } from "uuid";

const linksColors = ["primary", "secondary"];

export const Project = ({
  title,
  description,
  thumbnail,
  images,
  imageFill,
  technologies,
  duration,
  isMobileApp,
  links,
}) => {
  const logos = useLogo();
  return (
    <div className="split-item">
      <div
        className="split-item-content reveal-from-left"
        data-reveal-container=".split-item"
      >
        {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
          Lightning fast workflow
        </div> */}
        <div className="title-container">
          <img src={thumbnail} className="project-logo" />
          <h3 className="mt-0 mb-0">{title}</h3>
        </div>
        <p className="m-0">{description}</p>
        <div className="technologies-container">
          <p className="technologies-heading">Technologies:</p>
          <p className="technologies">{technologies}</p>
        </div>
        <div className="technologies-container">
          <p className="technologies-heading">Duration:</p>
          <p className="technologies">{duration}</p>
        </div>
        <div className="links-container">
          {Object.keys(links)?.map((name, index) => (
            <Image
              key={uuid()}
              src={logos[name]}
              alt="icon"
              width={40}
              height={40}
              onClick={() => openInNewTab(links[name])}
              style={{
                cursor: "pointer",
                margin: 0,
              }}
            />
          ))}
        </div>
      </div>
      <div
        className={classNames(
          "split-item-image center-content-mobile reveal-from-bottom",
          imageFill && "split-item-image-fill"
        )}
        data-reveal-container=".split-item"
      >
        <ProjectGallery images={images} isMobileApp={isMobileApp} />
      </div>
    </div>
  );
};
