import React, { forwardRef, useImperativeHandle, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./NavigationBullets.css";
import { BulletNavRadio } from "../customRadio/BulletNavRadio/BulletNavRadio";

export const NavigationBullets = forwardRef(
  ({ heroRef, workRef, projectRef }, ref) => {
    useImperativeHandle(ref, () => {
      return {
        setRadioVal,
      };
    });
    const [value, setValue] = useState("hero");
    const setRadioVal = (val) => {
      setValue(val);
    };
    const scrollToHero = () => {
      heroRef.current.scrollToComponent();
    };
    const scrollToWork = () => {
      workRef.current.scrollToComponent();
    };
    const scrollToProjects = () => {
      projectRef.current.scrollToComponent();
    };
    const valueActionMap = {
      hero: scrollToHero,
      work: scrollToWork,
      projects: scrollToProjects,
    };
    const handleChange = (event) => {
      const val = event.target.value;
      setValue(val);
      valueActionMap[val]();
    };
    return (
      <div className="bullets-container">
        <RadioGroup
          defaultValue="hero"
          name="radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="hero"
            control={<BulletNavRadio />}
            label=""
          />
          <FormControlLabel
            value="work"
            control={<BulletNavRadio />}
            label=""
          />
          <FormControlLabel
            value="projects"
            control={<BulletNavRadio />}
            label=""
          />
        </RadioGroup>
      </div>
    );
  }
);
