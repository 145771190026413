import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { WorkExperience } from "../WorkExperience";
import { workExperience } from "../../data";
import { useOnScreen } from "../../hooks/useOnscreen";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = forwardRef(
  (
    {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      radioRef,
      ...props
    },
    ref
  ) => {
    const workRef = useRef(null);
    useImperativeHandle(ref, () => {
      return { scrollToComponent };
    });
    const onScreen = useOnScreen(workRef);
    useEffect(() => {
      if (onScreen){
        radioRef.current.setRadioVal('work')
      }
    }, [onScreen])
    
    const scrollToComponent = () => {
      workRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const outerClasses = classNames(
      "features-tiles section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner pt-0",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames(
      "tiles-wrap center-content",
      pushLeft && "push-left"
    );

    const sectionHeader = {
      title: "Work Experience",
      paragraph: "",
      // "I have experience of 3+ years in well reputed companies. I've played key role in each of " +
      // "the companies.",
    };

    return (
      <section {...props} className={outerClasses} style={{
        paddingTop: 30
      }}>
        <div className="container" ref={workRef}>
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              {workExperience.map((i, index) => (
                <WorkExperience
                  key={index}
                  companyName={i.companyName}
                  workDescription={i.workDescription}
                  companyLogo={i.companyLogo}
                  link={i.link}
                  start={i.start}
                  end={i.end}
                  title={i.title}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
);

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
