import React from "react";
import Image from "../elements/Image";
import "./index.css";
export const ProjectGallery = ({ images, isMobileApp }) => {
  if (isMobileApp)
    return (
      <div className="project-gallery">
        {images?.map((image) => (
          <div className="zoom">
            <Image
              src={image}
              alt="Project Image"
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        ))}
      </div>
    );
  return (
    <div className="zoom-web">
      <Image
        src={images[0]}
        alt="Project Image"
        style={{
          cursor: "pointer",
        }}
      />
    </div>
  );
};
