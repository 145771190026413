import React from "react";
import "./index.css";
export const Contact = () => {
  return (
    <div>
      <h4>Contact</h4>
      <p>
        <span className="label">Mobile</span>
        <a href="tel:+923134243117">(+92) 313-4243117</a>
      </p>
      <p>
        <span className="label">Email</span>
        <a href="mailto:me@mubasher.site">me@mubasher.site</a>
      </p>
      <p>
        <span className="label">Address </span>
        <a>Lahore, Pakistan</a>
      </p>
    </div>
  );
};
