import * as React from "react";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";

const Icon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  border: null,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  "input:hover ~ &": {
    border:  "2px solid rgba(158, 150, 150, .5)",
  },
}));

const IconChecked = styled(Icon)({
  backgroundColor: "#3498DB",
  "input:hover ~ &": {
    border:  "2px solid rgba(246, 105, 5, 0.5)",
  },
});

export function BulletNavRadio(props) {
  return (
    <Radio
      disableRipple
      color="primary"
      checkedIcon={<IconChecked />}
      icon={<Icon />}
      {...props}
    />
  );
}
