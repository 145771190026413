import Playstore from "../assets/images/playstore.png";
import Appstore from "../assets/images/appstore_copy.png";
import GithubLogo from "../assets/images/github.png";
import WebsiteLogo from "../assets/images/website_link.png";

export const useLogo = (name) => {
  return {
    playStore: Playstore,
    appStore: Appstore,
    github: GithubLogo,
    website: WebsiteLogo,
  };
};
