import React from "react";
import { PORTFOLIO_TYPE } from "../../consts/config";
import { openInNewTab } from "../../utils/commons";
import Image from "../elements/Image";
import './index.css'
export const WorkExperience = ({
  companyName,
  companyLogo,
  title,
  start,
  end,
  link,
  workDescription,
}) => {
  const openLink = () => openInNewTab(link);
  return (
    <div className="tiles-item reveal-from-bottom">
      <div className="tiles-item-inner">
        <div className="img-container">
          <Image
            src={companyLogo}
            alt="icon"
            width={64}
            height={64}
            onClick={openLink}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
        <div className="features-tiles-item-content" style={{ marginTop: 10 }}>
          <h4 className="mt-0 mb-0" style={{
              cursor: "pointer",
            }} onClick={openLink}>{companyName}</h4>
          <p className="job-title">{title}</p>
          <p className="tenure">{start + " - " + end}</p>
          <p className="m-0 text-sm">{workDescription[PORTFOLIO_TYPE]}</p>
        </div>
      </div>
    </div>
  );
};
