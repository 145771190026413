import React from "react";
import { workExperience } from "../../data";
import "./index.css";
import Image from "../elements/Image";

export const FooterExperience = () => {
  return (
    <div className="main-container footer-details-rep">
      <h4>Work Experience</h4>
      <div className="projects-container">
        {workExperience.map((project, index) => {
          if (index < 4)
            return (
              <div className="footer-project">
                <Image
                  src={project.companyLogo}
                  alt="Project Image"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>
            );
        })}
      </div>
    </div>
  );
};
